<template>
<div v-if="artPiecesGroupingsOk && artPiecesOk">
	<v-container fluid style="padding:0px; margin-bottom: -128px; max-width:1920px; maring:auto;">
	<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
		<v-row no-gutters style="margin-top:0px;">
			<v-carousel hide-delimiters :height="heightSize" show-arrows-on-hover cycle>
				<v-carousel-item
					v-for="(item,i) in artPiecesThemes"
					:key="i"
					eager
				>
						<!--
						:src="require(`../assets/ArtDetails/Products_Desktop_03.jpg`)" 
						-->
					<v-img
						:src="require(`../assets/${getImageById(item.idImage)}`)"
						:lazy-src="require(`../assets/Mini-Pictures/${getImageById(item.idImage).split('.')[0]}.jpg`)"
						:max-height="heightSize" 
						:max-width="widthSize"
						:height="heightSize"
						:width="widthSize"
						position="center bottom"
						:contain="false"
					>
						<v-row no-gutters class="fill-height ma-0" align="end" justify="start" >
							<v-col cols="12"
								style="height:239px; display:flex; flex-direction:column; justify-content:space-around;"
								:style="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?
									'margin-left:50px; max-width:350px; margin-bottom:100px;':
									'margin-left:10px; max-width:200px; margin-bottom:100px;'"
							>
								<h1 class="poppinsExtraBoldFont uppercase noSelect shadow-category">
									<span style="word-break: normal;" 
										:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'h1-font-big-Desktop':'h1-font-big-Mobile'"
									>
										{{trimString(item.name.split("|")[languageId])}}
									</span>
								</h1>
								<v-hover v-slot="{hover}">
									<a class="mt-2 borderOrange abtn" :class="hover?'backgroundOrange':'backgroundTransparent'" :href="artPiecesUrlByThemeId(item.id)" style="text-decoration:none; text-transform: uppercase; text-align: center;">
										<span class="poppinsRegularFont" 
											:class="hover?'colorWhite poppinsBlackFont':'colorWhite shadow-category'"
											style="font-size:20px;"
										>
											{{shopNow[languageId]}}
										</span>
									</a>
								</v-hover>
							</v-col>
						</v-row>
					</v-img>
				</v-carousel-item>
			</v-carousel>
		</v-row>

		<!-- <v-row no-gutters align="center" justify="center" class="py-8 backgroundWhite" :style="`background-image:url(${require('../assets/Home/FloresFondo_02.png')}); background-position: bottom; background-size: contain;`"> -->
		<v-row no-gutters align="center" justify="center" class="py-8 backgroundWhite">
			<v-col cols="12" class="my-4">
				<h2 class="poppinsExtraBoldFont colorGreen uppercase noSelect" align="center" style="word-break: normal;"
					:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'h1-font-medium-Desktop':'h1-font-medium-Mobile'"
				>
					{{trimString(artworkHighlighted[languageId])}}
				</h2>
			</v-col>
			<v-col cols="12">
				<v-slide-group class="py-4" show-arrows="always">
					<v-hover v-slot="{hover}" v-for="(item,i) in artPiecesCategories" :key="i" >
						<v-slide-item
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'mx-7':'mx-1'"
						>
							<v-card flat :class="hover?'hover-pointer':''"
								:max-width="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?300:208"  
								:max-height="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?700:500"
							>
								<v-img
									:src="require(`../assets/${getImageById(item.idImage)}`)"
									:lazy-src="require(`../assets/Mini-Pictures/${getImageById(item.idImage).split('.')[0]}.jpg`)"
									:max-height="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?400:250" 
									:max-width="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?300:208"
									:height="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?400:250"
									:width="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?300:208"
									position="center"
									:contain="false"
								>
									<template v-if="hover">
										<a :href="`${artPiecesUrl}/${themeUrl}/${getArtPieceByIdImage(item.idImage).title.split('|')[languageId]}`" class="abtn">
											<!-- <div style="width:100%; height:100%;" class="flex-center backgroundLightWhite"  @click="redirectUrl(`${artPiecesUrl}/${themeUrl}/${getArtPieceByIdImage(item.idImage).title.split('|')[languageId]}`)"> -->
											<div style="width:100%; height:100%;" class="flex-center backgroundLightWhite">
												<span class="poppinsExtraBoldFont colorGreen uppercase" 
													:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-big':'p-font-small'"
												>
													{{seeMore[languageId]}}
												</span>
											</div>
										</a>
									</template>
								</v-img>
								<v-card-title class="poppinsExtraBoldFont colorBlack noSelect" 
									:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-big':'p-font-medium no-padding py-4'"
								>
									{{trimString(getArtPieceByIdImage(item.idImage).title.split("|")[languageId])}}
								</v-card-title>
								<v-card-subtitle class="poppinsRegularFont colorBlack noSelect" 
									:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
								>
									{{chopString(trimString(getArtPieceByIdImage(item.idImage).history.split("|")[languageId]))}}
								</v-card-subtitle>
								<v-card-actions class="poppinsExtraBoldFont colorDarkGrey noSelect">
									<strong v-if="getArtPieceByIdImage(item.idImage).price!=null"
										:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small'"
									>
										{{trimString(startingPriceLabel[languageId])}} $ {{trimString(getArtPieceByIdImage(item.idImage).price.toLocaleString(languageString))}}
									</strong>
								</v-card-actions>
							</v-card>
						</v-slide-item>
					</v-hover>
				</v-slide-group>
			</v-col>
			<v-col cols="12" class="my-4" style="text-align:center;">
				<v-hover v-slot="{hover}" style="z-index:10;">
					<v-btn class="mt-2 borderGreen" :class="hover?'backgroundTransparent':'backgroundGreen'" @click="redirectUrl(artPiecesUrl)">
						<span class="poppinsBlackFont " :class="hover?'colorGreen':'colorWhite'">{{seeAll[languageId]}}</span>
					</v-btn>
				</v-hover>
			</v-col>
		</v-row>

		<div style="margin-top:-100px; display:flex; flex-direction:column; position:relative; top:0px;" v-if="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'">
			<div :style="`background-image:url(${require('../assets/Home/flor_1.svg')}); height: 200px; width:200px; background-position: left; opacity: 0.29;`"></div>
			<div :style="`background-image:url(${require('../assets/Home/flor_2.svg')}); height: 100px; width:100px; background-position: right; margin-top: -160px; opacity: 0.29; align-self:flex-end`"></div>
		</div>
		<div style="margin-top:-100px; display:flex; flex-direction:column; position:relative; top:0px;" v-else>
			<div :style="`background-image:url(${require('../assets/Home/flor_1.svg')}); height: 200px; width:200px; background-position: left; opacity: 0.29;`"></div>
			<div :style="`background-image:url(${require('../assets/Home/flor_2.svg')}); height: 100px; width:100px; background-position: right; margin-top: -160px; opacity: 0.29; align-self:flex-end`"></div>
		</div>

		<!-- <v-row no-gutters align="center" justify="center" class="py-8 backgroundLightGrey-Home" :style="`background-image:url(${require('../assets/Home/FloresFondo_03.png')}); background-position: center; background-size: contain;`"> -->
		<v-row no-gutters align="center" justify="center" class="py-8 backgroundLightGrey-Home" style="margin-top:-50px;">
			<v-col cols="12" class="my-4">
				<h2 class="poppinsBoldFont colorYellow uppercase noSelect" align="center" style="word-break: normal;"
					:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'h1-font-medium-Desktop':'h1-font-medium-Mobile'"
				>
					{{trimString(artworkThemesLabel[languageId])}}
				</h2>
			</v-col>
			<v-col cols="12">
				<v-slide-group class="py-4" show-arrows="always">
					<v-slide-item class="mx-2" v-for="(item,i) in artPiecesThemes" :key="i" >
						<v-hover v-slot="{hover}" >
							<v-row justify="center" style="max-width:180px;" :class="hover?'hover-pointer':''">
								<!-- <v-col cols="12" style="text-align:center;"  @click="redirectUrl(`${artPiecesUrl}/${item.name.split('|')[languageId]}`)"> -->
								<v-col cols="12" style="text-align:center;">
									<a class="abtn" :href="`${artPiecesUrl}/${item.name.split('|')[languageId]}`">
										<v-avatar
											size="150"
										>
											<v-img
												:src="require(`../assets/${getImageById(item.idImage2)}`)"
												:lazy-src="require(`../assets/Mini-Pictures/${getImageById(item.idImage2).split('.')[0]}.jpg`)"
												:max-height="150" 
												:max-width="150"
												:height="150"
												:width="150"
												position="center"
												:contain="false"
											>
												<template v-if="hover">
													<div style="width:100%; height:100%;" class="flex-center backgroundLightYellow">
													</div>
												</template>
											</v-img>
										</v-avatar>
									</a>
								</v-col>
								<v-col cols="12" style="text-align:center;" 
									:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-big':'p-font-medium no-padding py-4'"
								>
									<p class="uppercase noSelect break-on-whitespace poppinsExtraBoldFont" style="margin:0px;"
										:class="hover?'colorYellow':'colorBlack'"
									>
										{{trimString(item.name.split("|")[languageId])}}
									</p>
								</v-col>
							</v-row>
						</v-hover>
					</v-slide-item>
				</v-slide-group>
			</v-col>
			<v-col cols="12" class="my-4" style="text-align:center;">
				<v-hover v-slot="{hover}" style="z-index:10;">
					<v-btn class="mt-2 borderYellow" :class="hover?'backgroundTransparent':'backgroundYellow'" @click="redirectUrl(artPiecesUrl)">
						<span class="poppinsBlackFont colorGreen" >{{seeAll[languageId]}}</span>
					</v-btn>
				</v-hover>
			</v-col>
		</v-row>

		<div style="margin-top:-140px; display:flex; flex-direction:column; position:relative; top:0px;" v-if="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'">
			<div :style="`background-image:url(${require('../assets/Home/flor_3.svg')}); height: 300px; width:300px; background-position: left; opacity: 0.29;`"></div>
			<div :style="`background-image:url(${require('../assets/Home/flor_4.svg')}); height: 200px; width:200px; background-position: right; margin-top: -240px; opacity: 0.29; align-self:flex-end`"></div>
		</div>
		<div style="margin-top:-155px; display:flex; flex-direction:column; position:relative; top:0px;" v-else>
			<div :style="`background-image:url(${require('../assets/Home/flor_3.svg')}); height: 300px; width:300px; background-position: left; opacity: 0.29;`"></div>
		</div>

		<!-- <v-row no-gutters align="center" justify="center" class="py-8 backgroundWhite" :style="`background-image:url(${require('../assets/Home/FloresFondo_04.png')}); background-position: top; background-size: contain;`"> -->
		<v-row no-gutters align="center" justify="center" class="py-8 backgroundWhite" style="margin-top:-120px;">
			<v-col cols="12" class="my-4">
				<h2 class="poppinsExtraBoldFont colorOrange uppercase noSelect" align="center" style="word-break: normal;"
					:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'h1-font-medium-Desktop':'h1-font-medium-Mobile'"
				>
					{{trimString(collectionsLabel[languageId])}}
				</h2>
			</v-col>
			<v-col cols="12">
				<v-carousel hide-delimiters show-arrows height="fit-content" cycle>
					<v-carousel-item v-for="(item,i) in artPiecesCollections" :key="i" eager>
				<!-- <v-slide-group class="py-4" show-arrows="always">
					<v-slide-item v-for="(item,i) in artPiecesCollections" :key="i"> -->
						<v-row :style="`width:${widthSize}px`" no-gutters>
							<v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
							<v-col cols="12" sm="12" md="6" lg="6" xl="6" :style="`min-height:${imageHeight()}px;`">
								<v-img
									:src="require(`../assets/${getImageById(item.idImage)}`)"
									:lazy-src="require(`../assets/Mini-Pictures/${getImageById(item.idImage).split('.')[0]}.jpg`)"
									:max-height="imageHeight()" 
									:max-width="widthSize"
									position="center"
									:contain="false"
								>
								</v-img>
							</v-col>
							<v-col cols="12" sm="12" md="4" lg="4" xl="4" style="margin:auto;" class="ml-1">
								<v-card flat class="backgroundTransparent">
									<v-card-title class="poppinsExtraBoldFont colorBlack noSelect" style="word-break: normal;"
										:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-big':'p-font-medium no-padding py-4'"
									>
										{{trimString(replaceSpaces(item.name.split("|")[languageId]))}}
									</v-card-title>
									<v-card-subtitle class="poppinsExtraLightFont">
										<hr width="100" :style="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'height:5px;':'height:2px;'" class="backgroundBlack borderBlack"/>
									</v-card-subtitle>
									<v-card-text class="poppinsRegularFont colorBlack noSelect" style="word-break: normal;"
										:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
									>
										{{trimString(item.description.split("|")[languageId])}}
									</v-card-text>
									<v-card-actions class="poppinsExtraBoldFont">
										<v-hover v-slot="{hover}">
											<v-btn class="mt-2 borderOrange" :class="hover?'backgroundTransparent':'backgroundOrange'" @click="redirectArtPiecesUrlByCollectionId(item.id)">
												<span class="poppinsRegularFont" :class="hover?'colorOrange poppinsBlackFont':'colorWhite'">{{shopNow[languageId]}}</span>
											</v-btn>
										</v-hover>
									</v-card-actions>
								</v-card>
							</v-col>
							<v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
						</v-row>
					<!-- </v-slide-item>
				</v-slide-group> -->
					</v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row>

		<v-row no-gutters align="center" justify="center" class="py-8 backgroundBlack black-background" :style="`background-image:url(${require('../assets/Home/FondoNegro1.jpg')}),url(${require('../assets/Home/FondoNegro2.jpg')});`">
			<v-col cols="12" class="my-4">
				<h2 class="poppinsExtraLightFont colorBeige uppercase noSelect break-on-whitespace" align="center"
					:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'h1-font-medium-Desktop':'h1-font-medium-Mobile'"
					v-html="buyingQuestionLabel[languageId]"
				></h2>
			</v-col>
			<v-col cols="12" class="mb-4 px-8">
				<v-row justify="center">
					<v-col cols="12" sm="12" md="12" lg="4" xl="4" style="text-align:center; max-width:300px;">
						<v-avatar size="150">
							<v-img
								src="../assets/Home/cuadros-de-paisajes.png"
								lazy-src="../assets/Home/cuadros-de-paisajes.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="white-space: break-spaces; margin:0px;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(buyingAnswer1[languageId])}}
						</p>
					</v-col>
					<v-col cols="12" sm="12" md="12" lg="4" xl="4" style="text-align:center; max-width:300px;">
						<v-avatar size="150">
							<v-img
								src="../assets/Home/cuadros-de-momentos.png"
								lazy-src="../assets/Home/cuadros-de-momentos.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="margin:0px; word-break: normal;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(buyingAnswer2[languageId])}}
						</p>
					</v-col>
					<v-col cols="12" sm="12" md="12" lg="4" xl="4" style="text-align:center; max-width:300px;">
						<v-avatar size="150">
							<v-img
								src="../assets/Home/cuadros-con-detalles.png"
								lazy-src="../assets/Home/cuadros-con-detalles.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="white-space: break-spaces; margin:0px;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(buyingAnswer3[languageId])}}
						</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<v-row no-gutters align="center" justify="center" class="py-8 backgroundOrange">
			<v-col cols="12" class="mb-4 px-8">
				<v-row justify="center">
					<v-col cols="12" sm="12" md="3" lg="3" xl="3" style="text-align:center; max-width:300px;">
						<v-avatar size="150" rounded>
							<v-img
								src="../assets/Home/Iconos_09.png"
								lazy-src="../assets/Home/Iconos_09.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="white-space: break-spaces; margin:0px; word-break: normal;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(otherAnswer1[languageId])}}
						</p>
					</v-col>
					<v-col cols="12" sm="12" md="3" lg="3" xl="3" style="text-align:center; max-width:300px;">
						<v-avatar size="150" rounded>
							<v-img
								src="../assets/Home/Iconos_11.png"
								lazy-src="../assets/Home/Iconos_11.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="margin:0px; word-break: normal; word-break: normal;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(otherAnswer2[languageId])}}
						</p>
					</v-col>
					<v-col cols="12" sm="12" md="3" lg="3" xl="3" style="text-align:center; max-width:300px;">
						<v-avatar size="150" rounded>
							<v-img
								src="../assets/Home/Iconos_13.png"
								lazy-src="../assets/Home/Iconos_13.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="white-space: break-spaces; margin:0px; word-break: normal;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(otherAnswer3[languageId])}}
						</p>
					</v-col>
					<v-col cols="12" sm="12" md="3" lg="3" xl="3" style="text-align:center; max-width:300px;">
						<v-avatar size="150" rounded>
							<v-img
								src="../assets/Home/Iconos_15.png"
								lazy-src="../assets/Home/Iconos_15.png"
								:max-height="150" 
								:max-width="150"
								:height="150"
								:width="150"
								position="center"
								:contain="false"
							>
							</v-img>
						</v-avatar>
						<p class="poppinsRegularFont colorBeige noSelect" style="white-space: break-spaces; margin:0px; word-break: normal;"
							:class="isSmallScreen=='Desktop' || isSmallScreen=='Tablet'?'p-font-medium':'p-font-small no-padding'"
						>
							{{trimString(otherAnswer4[languageId])}}
						</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<footerComponent :backgroundImage="footerImageLG"></footerComponent>
	</v-container>
</div>
</template>

<script>
import footerComponent from './components/Footer'
import { mapState, mapActions } from "vuex";
import axios from "axios";

const baseURL = process.env.VUE_APP_BASE_URL_API;

	export default {
		name: 'Home',
		components: { footerComponent },
		computed:{
            ...mapState("shoppingCart", 
				[
					"languageId", "shopNow", "seeAll", "seeMore",
					"artPieces", "images", "artPiecesOk", "baseArtPiecesUrl",
					"baseThemeUrl", "themesNames", "startingPriceLabel", "collectionsLabel",
				]),
			// ...mapState("about", [
			// 		"inspirationLabel","eyeHeaderLabel","cameraHeaderLabel","birdHeaderLabel",
			// 		"eyeTextLabel","cameraTextLabel","birdTextLabel",
            //     ]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth >= 1920)
					windowWidth = 1920
				return windowWidth
			},
			heightSize(){
				var windowWidth = window.innerWidth;
				var height = window.innerHeight;
				if(windowWidth <= 320)
					height = 320*1.5625
				if(windowWidth > 320 && windowWidth <= 640)
					height = windowWidth*1.5625
				if(windowWidth > 640 && windowWidth < 1024)
					height = 640*1.5625
				if(windowWidth >= 1024 && windowWidth <= 1440)
					height = windowWidth*0.5625
				if(windowWidth > 1440 && windowWidth < 1920)
					height = 1440*0.5625
				if(windowWidth >= 1920)
					height = 1014-128
				return height-128
			},
            languageString(){
                if(this.languageId == 0){
                    return 'es-ES';
                }
                if(this.languageId == 1){
                    return 'en-US';
                }
                return 'es-US'
            },
			artPiecesUrl(){
				return this.baseArtPiecesUrl[this.languageId];
			},
			themeUrl(){
				return this.baseThemeUrl[this.languageId]
			},
		},
		created(){
			this.getArtPiecesByGroupings()
		},
		updated(){
			if(this.requiresReload && this.artPiecesOk){
				this.metaImage = this.selectMetaImage(this.metaImageID);
				this.requiresReload = false;
			}
		},
		data: () => ({
			pageTitle:['Tienda de Karlus Morales','Karlus Morales Shop'],
			pageMetaTitle:['Tienda de Karlus Morales, Compra una Obra de Arte Fotográfica Única','Shop Karlus Morales, Buy a Unique Photographic Work of Art'],
			pageDescription:['Explora las impresionantes obras de arte fotográfica de Karlus Morales y añade un toque especial a tus espacios comprando sus imponentes cuadros ¡Compra ahora!','Explore the impressive photographic artworks of Karlus Morales and add a special touch to your spaces by buying his impressive works of art. Buy now!'],
            pageKeywords:['Karlus Morales, Compra de obra de arte fotográfica en Colombia','Karlus Morales, Purchase of photographic artwork in Colombia'],
            metaImage:'',
			metaImageID:97,

			footerImageLG:require('../assets/About/About_Desktop_06.jpg'),
			footerImageSM:require('../assets/About/Home_08.jpg'),
			artworkLabel:["Cuadros de:","Artwork of:"],
			artworkThemesLabel:["Categorías de cuadros", "ArtWork themes"],
			artworkHighlighted:["Cuadros destacados","Highlighted artwork"],
			buyingQuestionLabel:["¿Por qué comprar cuadros de <span class='poppinsExtraBoldFont'><em>Karlus</em> Morales</span>?","Reasons to buy <span class='poppinsExtraBoldFont'><em>Karlus</em> Morales</span> artwork!"],
			buyingAnswer1:["Cuadros de paisajes de todos los estilos y colores","Landmark Artworks in all styles and colours"],
			buyingAnswer2:["Cuadros de momentos y criaturas curiosas de la naturaleza","Moment Artworks and odd creatures in nature"],
			buyingAnswer3:["Cuadros con detalles y siluetas originales","Original silhouettes and details Artworks"],

			otherAnswer1:["Fabricamos tu cuadro en nuestro taller","We build your Artwork in our workshop"],
			otherAnswer2:["Lo entregamos en tu casa en máximo 20 días habiles","Delivered in your house in max 20 business days"],
			otherAnswer3:["Muchas opciones disponibles y exclusivas","Many available and exclusive options"],
			otherAnswer4:["Te ofrecemos garantia por daños en el transporte","We offer warranty for damages in transit"],

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),

			artPiecesGroupingsOk:false,
			artPiecesGroupings:[],
			artPiecesCategories:[],
			artPiecesThemes:[],
			artPiecesCollections:[],
			
			requiresReload:false,
		}),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'index, follow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		methods:{
            ...mapActions("shoppingCart", ["filter", ]),
			replaceSpaces(text){
				return text.replace(/\s/g," ")
			},
			imageHeight(){
				var height = 0;
				var windowWidth = window.innerWidth;
				var imageWidth = this.widthSize;
				if(windowWidth <= 320)
					height = 320*1.2
				if(windowWidth > 320 && windowWidth <= 640)
					height = imageWidth*1.315
				if(windowWidth > 640 && windowWidth < 1024)
					height = 640*1.315
				if(windowWidth >= 1024 && windowWidth <= 1440)
					height = imageWidth*.688*0.5625
				if(windowWidth > 1440 && windowWidth < 1920)
					height = 1440*.688*0.5625
				if(windowWidth >= 1920)
					height = 1014*.688
				return height
			},
            selectMetaImage(idImage){
				let image = this.getImageById(idImage)
				if(image){
					let imageRoute = window.location.origin + require(`../assets/${image}`) 
					return imageRoute
				}
				return ''
            },
			getImageById(idImage){
				let image = this.images?.find(x=>x.id == idImage)
				if(image) return image?.base64
				return ''
			},
			getArtPieceByIdImage(idImage){
				let image = this.images.find(x=>x.id == idImage)
				let artpiece = this.artPieces.find(x=>x.id == image.idArtPiece);
				return artpiece
			},
			async getArtPiecesByGroupings(){
                await axios.get(`${baseURL}ArtPieces/artpieces-by-grouping`)
                    .then(res => {
                        if(res.status == 200){
							this.artPiecesGroupings = res.data;
							this.artPiecesCategories = res.data.find(x=>x.groupingName=="IdCategory")?.grouping;
							this.artPiecesThemes = res.data.find(x=>x.groupingName=="IdTheme")?.grouping;
							this.artPiecesCollections = res.data.find(x=>x.groupingName=="IdCollection")?.grouping;
							this.artPiecesGroupingsOk = true;
							if(this.artPiecesOk){
							this.metaImage = this.selectMetaImage(this.metaImageID);
							} else {
								this.requiresReload = true;
							}
                        }
                    })
                    .catch(error => { 
                        console.log('sendRequest',error);
                    });
			},
			artPiecesUrlByThemeId(id){
				if(id!=null){
					this.filter({property:'themesFilter',with:id})
					let themeUrl = this.themesNames.find(x=>x.id == id)?.name?.split("|")[this.languageId];
					return `${this.artPiecesUrl}/${themeUrl}`;
				}
			},
			redirectArtPiecesUrlByThemeId(id){
				this.artPiecesUrlByThemeId(id);
			},
			redirectArtPiecesUrlByCollectionId(id){
				if(id!=null){
					this.filter({property:'collectionFilter',with:id})
					this.redirectUrl(`${this.artPiecesUrl}`);
				}
			},
			redirectUrl(path){
				this.$router.push(`/${path}`)
			},
			trimString(string){
				return string.trim();
			},
			chopString(string,size=30){
				return `${string.substring(0,size)} ...`;
			}
		}
	}
</script>

<style scoped>

.abtn{
	text-decoration:none; 
	text-transform: uppercase; 
	text-align: center;
}
.h1-font-big-Desktop{
	font-size: 50px;
}
.h1-font-medium-Desktop{
	font-size: 30px;
}
.h1-font-small-Desktop{
	font-size: 22px;
}

.h1-font-big-Mobile{
	font-size: 25px;
}
.h1-font-medium-Mobile{
	font-size: 20px;
}
.h1-font-small-Mobile{
	font-size: 16px;
}

.p-font-big{
	font-size: 20px;
}
.p-font-medium{
	font-size: 15px;
}
.p-font-small{
	font-size: 12px;
}

.cuadro {
	position: absolute; 
	top:0px; 
	left:0px; 
	right:0px; 
	z-index:10; 
	/* border:1px solid red;  */
	/* border-left: 2px solid white; */
	margin:0px 60px 60px 80px;
	/* padding: 10px; */
	background-image: linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(white,white);
	background-repeat: no-repeat;
	background-size: 2px 100%, 100% 2px, 2px 60%, 100% 2px, 2px 3% ; /*calc(100% - 4px) calc(100% - 4px);*/
	background-position: right bottom, right bottom, left top, left top, left bottom;
}
.black-background{
	background-color: #1c1c1c !important;
	background-size: contain, contain;
    background-position: left center, right center;
    background-repeat: repeat-y, repeat-y;
}

.backgroundLightGrey-Home{
	
	background-color:#F6F6F6 !important;
}
</style>